import Logo from '../assets/logo.svg'
import { FiArrowUpRight } from "react-icons/fi";

function Header() {
  return (
    <div className='[&>h1]:font-semibold  bg-gradient-to-r from-[#FFBC00] to-[#FF9100] flex flex-row justify-between items-center border border-b-4 border-black px-[10%]'>
        <img src={Logo} className='w-32 h-16'></img>
    
        <button className='flex items-center justify-between bg-white rounded-md px-4 py-1 buy-button-clip'><FiArrowUpRight />
    Buy $FUFI</button>
    </div>
  )
}

export default Header