import Logo from "../assets/footerLogo.svg";
import tg from "../assets/telegram.svg";
import tw from "../assets/twitter.svg";


function Footer() {
  return (
    <div className="flex flex-col bg-black px-[8%] text-white pt-[5%]">
      <div className="flex flex-col md:flex-row gap-6 items-center justify-center">
        <div className="flex flex-col basis-1/3 items-start gap-10">
          <img className="h-[25px]" src={Logo}></img>
          <h2 className="text-left">
            Welcome to Fufi, your gateway to the future of cryptocurrency
            innovation. Explore our platform to discover the next big thing in
            digital finance.
          </h2>
        </div>
        <div className="flex flex-row basis-1/3 text-[#9EB0CB] text-left gap-10 ml-0 lg:ml-10">
          <div className="flex flex-col gap-3">
          <h1>Our Journey</h1>
          <h1>Token</h1>
          <h1>Buy $FUFI</h1>
          <h1>FAQ</h1>

          </div>
          <div className="flex flex-col gap-3">
          <h1>Join Telegram</h1>
          <h1>Whitepaper</h1>
          <h1>Privacy Policy</h1>
          <h1>Terms Policy</h1>

          </div>
        </div>
        <div className="flex flex-col basis-1/6 items-start gap-6">
            <h1>Follow Us!</h1>
            <div className="flex gap-2">
              <img src={tw} alt="" />
              <a href="https://t.me/+dynDs98OuMNmM2E9"> <img src={tg} alt="" /></a>

            </div>
        </div>
      </div>

      <div className="flex flex-col w-full items-center pt-20">
        <div className="flex flex-col items-center">
          <h1 className="text-[#FF9100]">Disclaimer: </h1>
          <h2 className="max-w-3xl">Cryptocurrency regulations vary by jurisdiction. Cryptocurrency values may fluctuate, and profits may be taxable. Please research and consult with tax professionals before investing.</h2>
        </div>
        <hr className="max-w-3xl text-white"/>
        <div className="flex items-center">
            <h1 className="text-white border-t-slate-600 border-t-2 py-4 mt-10 w-full">
            Fufi Copyrights © 2024. All rights reserved
            </h1>
        </div>
      </div>
    </div>
  );
}

export default Footer;
