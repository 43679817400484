
function Hero() {
  return (
   <div className="flex flex-col items-center justify-center gap-6 min-h-[30vh] bg-hero-pattern bg-cover">
        <h1 className="text-[#FF9100] font-bold text-[60px] mt-32">STEP INTO THE WORLD OF FUFI</h1>
        
   </div>
  )
}

export default Hero