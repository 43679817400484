import React from 'react'

function HeroSub() {
  return (
    <div className='bg-hero-sub min-h-auto md:min-h-[160vh] flex flex-col items-center justify-start bg-contain bg-no-repeat gap-10'>
              <h2 className="font-bold text-[50px]">Unleashing the Next Big Meme<br/>Token in BSC</h2>

      <h3 className='mt-6'>$FUFI – Igniting the Cryptocurrency Revolution <br/> With Unprecedented Growth</h3>
        <button className="bg-[#FF9100] text-lg text-white px-10 py-2 rounded-md max-w-sm buy-button-clip drop-shadow-2xl">Buy $FUFI</button>
    </div>
  )
}

export default HeroSub