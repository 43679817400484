import mid1 from '../assets/mid1.svg'
import mid2 from '../assets/mid2.svg'
import mid3 from '../assets/mid3.svg'


function Mid() {
  return (
    <div className='w-full flex flex-col md:flex-row px-6 lg:px-32 pb-20 justify-between bg-hero-pattern bg-no-repeat bg-cover bg-center mt-32 gap-20 lg:gap-0'>
        <div className='flex flex-col gap-6'>
            <img src={mid1} alt="" className='max-w-[80%] lg:w-[250px] h-[190px]' />
            <h2 className='max-w-[90%] lg:max-w-[250px] text-left'>Welcome to Fufi, your gateway to the future of cryptocurrency innovation. Explore our platform to discover the next big thing in digital finance.</h2>
        </div>
        <div className='flex flex-col gap-6'>
            <img src={mid2} alt="" className='max-w-[80%] lg:w-[250px] h-[190px]' />
            <h2 className='max-w-[90%] lg:max-w-[250px] text-left'>Dreva: An Expansive,
Dynamic World Offering
Rich Play-To-Earn
Adventures And Player-
Driven Creativity In The
Heart Of The Metaverse.</h2>
        </div>
        <div className='flex flex-col gap-6'>
            <img src={mid3} alt="" className='max-w-[80%] lg:w-[250px] h-[190px]' />
            <h2 className='max-w-[90%] lg:max-w-[250px] text-left'>$FUFI Drives Fufimoon's
Play-To-Earn Model,
Offering Players A
Tangible Way To Earn,
Spend, And Invest Within
The Game's Economy.</h2>
        </div>
    </div>
  )
}

export default Mid