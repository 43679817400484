

function About() {
  return (
    <div className="px-6 lg:px-32 flex flex-col md:flex-row items-center justify-center gap-20 py-32 bg-hero-pattern bg-cover">
        <div className="flex flex-col gap-8 w-full  lg:w-1/2">
            <h1 className="text-left text-[#FF9100] font-bold">About $FUFI</h1>
            <h2 className="text-left max-w-auto lg:max-w-[70%]">$FUFI is a deflationary token supported by robust tokenomics and exclusive benefits, accessible through our presale.</h2>
            <h2 className="text-left max-w-auto lg:max-w-[70%]">Secure your position in the blockchain's strongest community by participating in our third and final Presale Phase now!</h2>
        </div>
        <div className="flex flex-col gap-20 justify-center pt-16 w-full lg:w-1/2">
            <h2 className="text-left">Purchase $FUFI with ETH directly from your web3 compatible wallet. After the public sale concludes, you'll be able to claim your $FUFI tokens through our dedicated claim page.</h2>
            <div className="flex gap-6">
                <button className="bg-[#FF9100] rounded-md px-4 py-1 buy-button-clip text-white">Buy Now</button>
                <button className="bg-white rounded-md px-4 py-1 border-black border-[2px]">How to Buy?</button>
            </div>
        </div>
    </div>
  )
}

export default About