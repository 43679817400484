import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import About from './sections/About';
import Hero from './sections/Hero';
import HeroSub from './sections/HeroSub';
import Mid from './sections/Mid';

function App() {
  return (
    <div className="App">
      <Header></Header>
      <Hero></Hero>
      <HeroSub></HeroSub>
      <Mid></Mid>
      <About></About>
      <Footer></Footer>
    </div>
  );
}

export default App;
